body {
  background-color: bisque;
  font-family: monospace;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-parent {
  display: flex;
  flex: 1;
  justify-content: space-evenly;
  align-items: start;
  gap: 40px;
  width: 100%;
  margin: 40px 0;
}

.contact-img {
  width: 350px;
  border-radius: 15px;
  height: 350px;
  object-fit: cover;
}

.write-h1 {
  margin-bottom: 40px;
  font-size: 3rem;
  color: #5a5a5a;
  text-align: center;
  width: 100%;
}

.form {
  display: flex;
  flex-direction: column;
  font: 1.4rem Arial, sans-serif;
  width: 100%;
  max-width: 500px;
}

input,
textarea {
  width: 100%;
  padding: 7px;
  outline: none;
  border-radius: 10px;
  border: 1px solid rgb(221, 185, 185);
  transition: border 0.3s ease;
  margin: 8px 0;
  font-size: 18px;
}

input:focus,
input:hover,
textarea:focus,
textarea:hover {
  border: 3px solid rgb(226, 171, 167);
}
#submitmessage {
  color: black;
}

textarea {
  height: 150px;
  resize: vertical;
  border-radius: 5px;
}

label {
  margin-top: 1rem;
}

.links-column {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}
.link-item {
  filter: grayscale(100%);
  transition: filter 0.3s ease;
  border: none;
}

.link-item :hover {
  filter: grayscale(0);
}
.link-item img {
  width: 50px;
  filter: grayscale(100%);
  transition: filter 0.3s ease;
  border: none;
}

.link-item img:hover {
  filter: grayscale(0);
}

@media (max-width: 768px) {
  .contact-img {
    display: none;
  }
  .write-h1 {
    font-size: 2rem;
    margin-top: 10px;
  }
  .form {
    margin: 0 auto;
    max-width: 300px;
  }
  .links-column {
    display: flex;
    flex-direction: row;
    justify-content: center;
    top: 0;
    width: 100%;
    align-items: center;
  }
  .form-parent {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
}

/* För tablets */
@media (max-width: 1024px) {
  .contact-img {
    width: 320px;
    height: 320px;
  }
}
