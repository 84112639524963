body {
  font-family: "Arial", sans-serif;
  /* height: 80vh; */
  min-width: 100%;
}

.outercard {
  background-color: #f9f4f4;
  padding: 25px;
  text-decoration: none;
}

.innercard {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  justify-content: center;
  align-content: start;
  grid-gap: 25px;
  padding: 20px;
}

.project-cards {
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px 20px;
  transition: transform 0.2s;
  display: flex;
  flex-direction: column;
}

.project-cards:hover {
  transform: translateY(-5px);
}

.img-style {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  height: 210px;
  max-width: 210px;
  border-radius: 8px;
  margin: 15px auto;
}

img {
  max-width: 100%;
  max-height: 100%;
  display: block;
}

.title,
.project-title {
  text-align: center;
}

.länkar,
.button-class {
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  background-color: #dfa6a0;
  text-decoration: none;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 14px;
}

.länkar:hover,
.button-class:hover {
  background-color: #c8847d;
}

.links {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  font-weight: 700;
}

.FaIcons {
  margin-left: 10px;
}

.description-text {
  font-weight: 600;
  margin-bottom: 15px;
  font-size: 15px;
}

@media screen and (max-width: 768px) {
  .description-text {
    font-size: 12px;
  }
}
@media screen and (min-width: 769px) {
  .img-style:hover {
    opacity: 0.9;
    transition: opacity 0.3s;
    transform: scale(1.5);
  }
}
