* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  overflow: auto;
  height: auto;
  width: 100%;
  padding: 10px 30px;
}
