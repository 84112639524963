@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

.aboutparent {
  display: grid;
  place-items: center;
  padding: 25px;
}

.about {
  display: grid;
  grid-template-columns: 600px 1fr;
  gap: 5rem;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-family: "Lato", sans-serif;
}
h1 {
  margin-bottom: 10px;
}

@media only screen and (max-width: 600px) {
  .about {
    grid-template-columns: 1fr;
    gap: 2rem;
    font-size: 17px;
    padding: 20px;
  }
}

@media only screen and (min-width: 601px) and (max-width: 768px) {
  .about {
    grid-template-columns: 400px 1fr;
    font-size: 18px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 992px) {
  .about {
    grid-template-columns: 500px 1fr;
    font-size: 19px;
  }
}

@media only screen and (min-width: 993px) {
  .about {
    grid-template-columns: 600px 1fr;
    font-size: 20px;
  }
}
